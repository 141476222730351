<wm-banner bannerTitleNoTransform="Summary of Benefits and Coverage Manual Finder"></wm-banner>
<div class="container">
    <wm-card styleClass="mar-30"
             *ngIf="!loading else spinner;">
        <p *ngIf="!hadError">
            Your file was downloaded and will open shortly.
        </p>
        <p *ngIf="hadError">
            There was an error retrieving your file. If you would like to try a new search, please go <a routerLink="/search">here</a>.
        </p>        
    </wm-card>
</div>
<ng-template #spinner>
    <wm-spinner></wm-spinner>
</ng-template>