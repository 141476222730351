<wm-accordion-tab #accordion header="{{ filterObject?.filterName }}" [subheader]="getSelectedValues()" hLevel="0">
    <div class="checkbox-wrapper" *ngFor="let option of filterObject.filterOptions; let i = index">
        <wm-checkbox 
            [value]="option.filterValue"
            [label]="option.filterValue"
            [name]="getCheckId(i)"
            [inputId]="getCheckId(i)"
            (change)="updateResults()"
            [binary]="true"
            [(ngModel)]="option.selected" 
            [ngModelOptions]="{standalone: true}"
            >
        </wm-checkbox>            
    </div>
</wm-accordion-tab>