import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FileSearch } from 'src/app/shared/models';
import { DatalayerService } from 'src/app/shared/services/datalayer.service';
import { FinderService } from 'src/app/shared/services/finder.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.scss']
})
export class ViewFileComponent implements OnInit{
  loading = true;
  hadError = false;
  constructor(
    private route: ActivatedRoute,
    private finderService: FinderService,
    private datalayerService: DatalayerService,
    private router: Router
  ) { }

  ngOnInit() {
    let healthId = this.route.snapshot.queryParams.h;
    let pharmId = this.route.snapshot.queryParams.d;
    if (healthId && healthId.length === 3) {
      if (environment.exchangeHealth.includes(healthId)) {
        healthId = 'MXM00' + healthId;
      } else {
        healthId = 'MCM00' + healthId;
      }
    }
    if (pharmId && pharmId.length === 3) {
      if (environment.exchangePharm.includes(pharmId)) {
        pharmId = 'RXM00' + pharmId;
      } else {
        pharmId = 'RCM00' + pharmId
      }
    }

    const request: FileSearch = {
      fileType: this.route.snapshot.queryParams.fileType || 'SBC',
      year: this.route.snapshot.queryParams.yr,
      productIdHealth: healthId,
      productIdPharmacy: pharmId,
      sbcId: this.route.snapshot.queryParams.sbcID,
      documentId: this.route.snapshot.queryParams.docID
    };

    this.finderService
      .getDirectDocumentUrl(request)
      .subscribe(
        (resp) => {
          window.location.href = resp.url;
        },
        (error: any) => {
          this.loading = false;
          this.hadError = true;
          this.datalayerService.sendCustomEvent('error_tracking', 
          {
            error_displayed_count: 1,
            error_http: error.status,
            error_location: this.router.url,
            error_displayed_text: 'There was an error retrieving your file.'
          }); 
        }
      );
    // this.finderService
    //   .getDirectDocument(request)
    //   .subscribe(
    //     (resp) => {
    //       this.loading = false;
    //       this.hadError = false;
    //     },
    //     (error: any) => {
    //       this.loading = true;
    //       this.hadError = true;
    //     }
    //   );
  }

}
