<span id="app-start" class="sr-only sr-only-focusable" tabindex="-1"></span>
<a id="skip-content" class="sr-only sr-only-focusable" (click)="skipToId()" (keyup.enter)="skipToId()" href="#">
  <span>Skip to main content</span>
</a>
<div class="body-content">
  <wm-navbar [environment]="env"></wm-navbar>
  <main>
    <div id="content-start" tabindex="-1"></div>
    <router-outlet></router-outlet>
  </main>
  <wm-footer></wm-footer>
</div>