import { Injectable, Type, Query } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of as observableOf  } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as searchActions from './actions';
import { State } from './state';
import * as searchSelectors from './selectors';
import { SearchRequest, SearchResult } from 'src/app/shared/models';
import { FinderService } from 'src/app/shared/services/finder.service';
import { SearchFacade } from './search-store.facade';

@Injectable()
export class SearchStoreEffects {
    constructor(
        private router: Router,
        private actions$: Actions,
        private searchState: SearchFacade,
        private finderService: FinderService,
        private store$: Store<State>
    ) { }

    
    requestSaved$ = createEffect(() => this.actions$.pipe(
        ofType(searchActions.SearchActionTypes.SAVE_REQUEST),
        tap(() => {
            this.router.navigate(['results']);
        })
    ), { dispatch: false });

    
    search$ = createEffect(() => this.actions$.pipe(
        ofType<searchActions.SearchAction>(searchActions.SearchActionTypes.SEARCH),
        withLatestFrom(this.searchState.request$),
        switchMap(([action, request]: [any, SearchRequest]) => {
            return this.finderService
                .getResults(request)
                .pipe(
                    switchMap((response: SearchResult[]) => [
                        new searchActions.SearchSuccessAction({ response }),
                        new searchActions.LoadingCompleteAction()
                    ]),
                    catchError(error => {
                        this.store$.dispatch(new searchActions.LoadingCompleteAction());
                        return observableOf(new searchActions.FailureAction({ error }));
                    })
                );
        })
    ));
}
