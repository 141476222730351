<wm-banner bannerTitleNoTransform="Summary of Benefits and Coverage Manual Finder"></wm-banner>
<div class="container">
  <ng-container *ngIf="(isLoading$ | async) === false; else spinner">
    <ng-container>
      <div class="results-header">
        <h2 class="mar-btm-15">
          <span class="plan-count">{{ results.length }}</span>
          <span *ngIf="results?.length !== 1"
                class="plan-count-text"> PLANS</span>
          <span *ngIf="results?.length === 1"
                class="plan-count-text"> PLAN</span>
        </h2>
        <div class="mar-btm-15">
          <p *ngIf="request.searchType === 'G'">
            {{ summaryText }}.</p>
          <p *ngIf="request.searchType === 'C'">
            Plans for {{ request.productIdSearch.health }}{{ (request.productIdSearch.drug && request.productIdSearch.drug.length
                    > 0) ? '/' + request.productIdSearch.drug : '' }} </p>
          <p *ngIf="request.searchType === 'W'">
            Plans for {{ request.wellmarkSearch.wellmarkId }}</p>
        </div>
        <p class="mar-btm-15">
          <a [routerLink]="['/search']"
             class="link-condensed link-alt trackCTA">NEW SEARCH</a>
        </p>
      </div>
      <!-- RESULTS -->
      <div *ngIf="results?.length !== 0"
           class="row">
        <div *ngIf="results?.length > 1"
             class="col-sbc-filter col-md-4 col-12">
          <div class="show-filters-modal">
            <a href="#"
               (click)="$event.preventDefault();filtersModal.show($event.target);sendModalEvent('open','filter_modal');"
               class="link-condensed link-icon link-icon-left">
              <span class="icon-filters"></span>
              <span class="link-text">Filter</span>
            </a>
          </div>
          <div class="filters-header">
            <h3 class="filter-text">FILTERS</h3>
            <a href="#"
               (click)="clearCheckFilters()"
               class="clear-filters link-condensed">CLEAR</a>
          </div>
          <div class="result-filters result-filters-desktop">
            <app-result-filter *ngFor="let filter of filters; last as isLast; first as isFirst"
                               [filterObject]="filter"
                               [isOnResultScreen]="true"
                               (valueChanged)="updateResults()"
                               class="result-filter"></app-result-filter>
          </div>
        </div>
        <div class="col-md-8 col-12">
          <div class="d-none d-md-block top-pagination">
            <wm-pagination #pager1
                           [numberOfRows]="totalResults"
                           [rowsPerPage]="rowsPerPage"
                           (pageChange)="pager2.setPage($event);changePage($event)"></wm-pagination>
          </div>
          <app-result-tile *ngFor="let result of displayedResults"
                           [isPublic]="!user.isProducer"
                           [result]="result"></app-result-tile>
          <div *ngIf="displayedResults.length === 0"
               class="panel panel-default">
            <div class="panel-body">
              <div class="row">
                <div class="col-12">
                  <p>
                    <strong>Sorry, we did not find any results for your search.</strong>
                    <br /> Please adjust your filters and try again.
                  </p>
                  <p>
                    If you can't find your plan, call us at
                    <span class="hide-phone-text">1-800-524-9242.</span>
                    <a class="hide-phone-link"
                       href="tel:1-800-524-9242">1-800-524-9242.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-pagination">
            <wm-pagination #pager2
                           [numberOfRows]="totalResults"
                           [rowsPerPage]="rowsPerPage"
                           (pageChange)="pager1.setPage($event);changePage($event)"></wm-pagination>
          </div>
        </div>
      </div>
      <!-- NO RESULTS -->
      <div *ngIf="results?.length === 0"
           class="row no-results">
        <wm-card>
          <p class="mar-btm-10">
            <strong>Sorry, we did not find any results for your search.</strong>
          </p>
          <p class="mar-btm-10">
            If you’re a member, easily find your plan by starting a new search using your Wellmark ID number.
          </p>
          <p class="mar-btm-10">Since not all coverage manuals are available online, we have provided sample manuals
            that describe
            the benefits and limitations of a typical large employer health insurance plan.</p>
          <p class="mar-btm-10">
            Still need assistance? You may call Wellmark Customer Service at
            <span class="hide-phone-text">1-800-524-9242.</span>
            <a class="hide-phone-link"
               href="tel:1-800-524-9242">1-800-524-9242.</a>
          </p>
          <p class="mar-btm-10">For Iowa:</p>
          <ul class="bullet-list mar-btm-10">
            <li>
              <a href="https://www.wellmark.com/CoverageManual/PDFs/Sample-Alliance-Select.pdf"
                 class="link-icon link-icon-right"
                 target="_blank">
                <span class="link-text">Preferred Provider Organization (PPO) Sample</span>
                <span class="sr-only"> This will open in a new window or tab</span>
                <span class="icon-file-pdf pad-icon"
                      aria-hidden="true"></span>
              </a>
            </li>
            <li>
              <a href="https://www.wellmark.com/CoverageManual/PDFs/Sample-Blue-Advantage.pdf"
                 class="link-icon link-icon-right"
                 target="_blank">
                <span class="link-text">Health Maintenance Organization (HMO) Sample</span>
                <span class="sr-only"> This will open in a new window or tab</span>
                <span class="icon-file-pdf pad-icon"
                      aria-hidden="true"></span>
              </a>
            </li>
            <li>
              <a href="https://www.wellmark.com/CoverageManual/PDFs/Sample-Blue-Choice.pdf"
                 class="link-icon link-icon-right"
                 target="_blank">
                <span class="link-text">Point of Service (POS) Sample</span>
                <span class="sr-only"> This will open in a new window or tab</span>
                <span class="icon-file-pdf pad-icon"
                      aria-hidden="true"></span>
              </a>
            </li>
          </ul>
          <p class="mar-btm-10">For South Dakota:</p>
          <ul class="bullet-list">
            <li>
              <a href="https://www.wellmark.com/CoverageManual/PDFs/Sample-Blue-Select.pdf"
                 class="link-icon link-icon-right"
                 target="_blank">
                <span class="link-text">Preferred Provider Organization (PPO) Sample</span>
                <span class="sr-only"> This will open in a new window or tab</span>
                <span class="icon-file-pdf pad-icon"
                      aria-hidden="true"></span>
              </a>
            </li>
          </ul>
        </wm-card>
      </div>
    </ng-container>
  </ng-container>
</div>
<!-- Modals -->
<!-- Filters Modal -->
<div class="modal light fade filters-modal"
     wmModal
     #filtersModal="wm-modal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="filtersModal"
     aria-hidden="true"
     [focusEl]="'a.done'"
     [config]="{backdrop: 'static'}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-primary modal-header-filters">
        <a href=""
           class="done link-condensed pull-right"
           (click)="$event.preventDefault();filtersModal.hide();sendModalEvent('close','filter_modal');"
           aria-label="Done">Done</a>
        <a href=""
           class="clear link-condensed pull-left"
           (click)="$event.preventDefault();filtersModal.hide();clearCheckFilters();;sendModalEvent('close','filter_modal');"
           aria-label="Clear">Clear</a>
        <h2 class="modal-header-title text-center">Filters</h2>
      </div>
      <div class="modal-body">
        <div class="result-filters modal-result-filters">
          <app-result-filter *ngFor="let filter of filters; last as isLast; first as isFirst"
                             [filterObject]="filter"
                             class="result-filter"
                             [isOnResultScreen]="true"
                             (valueChanged)="updateResults()"
                             [ngClass]="{'last': isLast, 'first': isFirst}"></app-result-filter>
        </div>
        <!-- <div class="modal-actions">
          <button class="wm-btn primary mar-r-15" id="cfm-Done" (click)="$event.preventDefault();filtersModal.hide()"
            (keyup.enter)="$event.preventDefault;filtersModal.hide()">Done</button>
          <a class="clear-btn" href="#" id="cfm-Clear" (click)="$event.preventDefault();filtersModal.hide()"
            (keyup.enter)="$event.preventDefault;filtersModal.hide()">Clear</a>
        </div> -->
        <span class="sr-only">End of dialog</span>
      </div>
    </div>
  </div>
</div>
<ng-template #spinner>
  <wm-spinner></wm-spinner>
</ng-template>