export const FilterList = [
    { filterTitle: 'Market Segment', filterField: 'marketSegment', showPublic: false, showProducer: false },
    { filterTitle: 'Network Type', filterField: 'networkType', showPublic: true, showProducer: true },
    { filterTitle: 'Individual Deductible', filterField: 'individualDeductible', showPublic: true, showProducer: true },
    { filterTitle: 'Product Name', filterField: 'productName', showPublic: true, showProducer: true },
    { filterTitle: 'Coverage Start Year', filterField: 'startYear', showPublic: true, showProducer: true },
    { filterTitle: 'ACA Metallic Tier', filterField: 'acaMetallicTier', showPublic: false, showProducer: true },
    { filterTitle: 'Grandfathered Status', filterField: 'grandfatheredPlan', showPublic: false, showProducer: true },
    { filterTitle: 'Exchange Plan', filterField: 'exchangePlan', showPublic: true, showProducer: true }
  ];

