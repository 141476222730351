import { Component, OnInit, ViewChild } from '@angular/core';

import { FinderService } from '../../shared/services/finder.service';
import { SearchResult, SearchRequest, Filter, User, FilterList } from 'src/app/shared/models';
import { SearchFacade } from '../../root-store/';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { DatalayerService } from 'src/app/shared/services/datalayer.service';

@Component({
  selector: 'app-results-container',
  templateUrl: './results-container.component.html',
  styleUrls: ['./results-container.component.scss']
})
export class ResultsContainerComponent implements OnInit {
  @ViewChild('pager1') pager1;
  @ViewChild('pager2') pager2;
  results: SearchResult[] = [];
  filteredResults: SearchResult[] = [];
  displayedResults: SearchResult[] = [];
  filters: Filter[];
  user: User;
  request: SearchRequest;
  isLoading$: Observable<boolean>;
  summaryText: string;
  currentPage = 1;
  lowNumber: number;
  highNumber: number;
  totalResults: number;
  rowsPerPage = 10;
  hadError = false;
  filterList;
  constructor(
    private finderService: FinderService,
    private searchState: SearchFacade,
    private router: Router,
    private datalayerService: DatalayerService
  ) { }

  ngOnInit() {
    this.finderService.setTitle('Wellmark Blue Cross Blue Shield | Summary of Benefits and Coverage Manual Finder - Results');
    this.isLoading$ = this.searchState.isLoading$;
    this.searchState.user$.subscribe((user: User) => {
      this.user = user;
      if (this.user.isProducer) {
        this.filterList = FilterList.filter(l => l.showProducer);
      } else {
        this.filterList = FilterList.filter(l => l.showPublic);
      }
    });

    this.searchState.searchError$.subscribe((error: any) => {
      this.hadError = !_.isNil(error);
      this.results = [];
    });

    this.searchState.request$.subscribe((request: SearchRequest) => {
      if (_.isNil(request)) {
        this.router.navigate(['/search']);
      }
      this.request = request;
      this.searchState.isLoading();
      this.searchState.search();
    });

    this.searchState.response$.subscribe((response: SearchResult[]) => {
      if (response) {
        this.results = response;
        this.totalResults = this.results.length;
        this.displayedResults = (this.results.length > 10) ? this.results.slice(0, 10) : this.results;

        if (this.request.searchType === 'G') {
          this.setGeneralSearchSummary();
        }

        this.buildFilters();
      }
    });
  }

  buildFilters() {
    if (!this.hadError) {
      const allFilters = [];
      this.filters = [];
      this.filterList.forEach(f => {
        const newFilter: Filter = {
          filterName: f.filterTitle,
          filterOptions: []
        };

        if (f.filterField !== 'individualDeductible') {
          this.results.forEach(element => {
            const value = element[f.filterField];
            if (!_.isNil(value)) {
              if (newFilter.filterOptions.filter(o => o.filterValue.toString() === element[f.filterField].toString()).length === 0) {
                if (element[f.filterField] && element[f.filterField].toString().length > 0) {
                  newFilter.filterOptions.push({ filterValue: element[f.filterField].toString(), selected: false });
                }
              }
            }
          });
          newFilter.filterOptions.sort((a, b) => {
            return a.filterValue.toUpperCase() < b.filterValue.toUpperCase()
              ? -1 : a.filterValue.toUpperCase() > b.filterValue.toUpperCase() ? 1 : 0;
          });
        } else {
          this.results.forEach(element => {
            const filterText = this.getDeductibleValue(+element[f.filterField]);
            if (newFilter.filterOptions.filter(o => o.filterValue.toString() === filterText).length === 0) {
              newFilter.filterOptions.push({ filterValue: filterText, selected: false });
            }
          });
          newFilter.filterOptions.sort((a, b) => {
            const aValue = +a.filterValue.split('-')[0].replace(/[^0-9-.]/g, '');
            const bValue = +b.filterValue.split('-')[0].replace(/[^0-9-.]/g, '');
            return aValue < bValue
              ? -1 : aValue > bValue ? 1 : 0;
          });
        }

        allFilters.push(newFilter);
      });
      this.filters = allFilters.filter(f => f.filterOptions.length > 1);
    }

  }

  changePage(newPage: string) {
    this.currentPage = Number(newPage);

    this.lowNumber = (this.rowsPerPage * (this.currentPage - 1)) + 1;
    this.highNumber = (this.rowsPerPage * (this.currentPage - 1)) + this.rowsPerPage;
    if (this.highNumber > this.totalResults) {
      this.highNumber = this.totalResults;
    }

    if (this.filteredResults && this.filteredResults.length > 0) {
      this.displayedResults = this.filteredResults.slice(this.lowNumber - 1, this.highNumber);
    } else {
      this.displayedResults = this.results.slice(this.lowNumber - 1, this.highNumber);
    }
  }

  updateResults() {
    this.filteredResults = [];
    const setFilters = this.filters.filter(f => f.filterOptions.filter(ff => ff.selected).length > 0);
    let newResults: SearchResult[] = [];
    let filterCount = 0;
    if (setFilters.length > 0) {
      setFilters.forEach(f => {
        const availableResults = (newResults.length > 0) ? newResults : (filterCount === 0) ? this.results : this.filteredResults;
        const filterName = this.filterList.filter(l => l.filterTitle === f.filterName)[0].filterField;
        const validResults: SearchResult[] = [];
        if (filterName === 'individualDeductible') {
          f.filterOptions.filter(ff => ff.selected).forEach(selected => {
            if (selected.filterValue.toUpperCase() === '$7500 AND ABOVE') {
              validResults.push(...availableResults.filter(r => r.individualDeductible > 7499));
            } else {
              const values = selected.filterValue.split('$').join('').split('-');
              if (values.length === 1) {
                validResults.push(...availableResults.filter(r => r.individualDeductible === 0));
              } else {
                validResults.push(...availableResults
                  .filter(r => r.individualDeductible >= +values[0] && r.individualDeductible <= +values[1]));
              }
            }
          });
        } else {
          f.filterOptions.filter(ff => ff.selected).forEach(selected => {
            validResults.push(...availableResults.filter(r => r[filterName].toString() === selected.filterValue.toString()));
          });
        }
        if (filterCount === 0) {
          newResults.push(...validResults);
        } else {
          newResults = validResults;
        }

        filterCount++;
      });
      this.filteredResults = newResults;
      this.totalResults = this.filteredResults.length;
      this.displayedResults = (newResults.length > 10) ? newResults.slice(0, 10) : newResults;
    } else {
      this.totalResults = this.results.length;
      this.displayedResults = (this.results.length > 10) ? this.results.slice(0, 10) : this.results;
    }
    this.pager1.setPage('1');
    this.pager2.setPage('1');
    this.changePage('1');
  }


  clearCheckFilters() {
    this.filters.forEach(filter => {
      filter.filterOptions.forEach(o => { o.selected = false; });
    });
    this.updateResults();
  }

  setGeneralSearchSummary() {
    this.summaryText = '';
    if (this.request.generalSearch.marketSegment) {
      this.summaryText = this.request.generalSearch.marketSegment.replace('Plans', '') + ' Plans in ';
    } else if (this.request.generalSearch.insuranceCategory) {
      if (this.request.generalSearch.insuranceCategory === 'O') {
        if (this.request.generalSearch.insuranceType === 'I') {
          this.summaryText = 'Individual and Family Plans in ';
        } else if (this.request.generalSearch.insuranceType === 'F') {
          this.summaryText = 'Farm Bureau Individual and Family Plans in ';
        } else {
          this.summaryText = 'Farm Bureau Health Plans in ';
        }
      } else {
        this.summaryText = 'Employer group plans in ';
      }
    }
    this.summaryText = `${this.summaryText} ${(this.request.generalSearch.coverageState === 'IA') ? 'Iowa' : 'South Dakota'}`;
  }


  getDeductibleValue(deductible: number): string {
    if (deductible === 0) {
      return '$0';
    } else if (deductible > 0 && deductible <= 999) {
      return '$50-$999';
    } else if (deductible > 999 && deductible <= 1999) {
      return '$1000-$1999';
    } else if (deductible > 1999 && deductible <= 2999) {
      return '$2000-$2999';
    } else if (deductible > 2999 && deductible <= 3999) {
      return '$3000-$3999';
    } else if (deductible > 3999 && deductible <= 4999) {
      return '$4000-$4999';
    } else if (deductible > 4999 && deductible <= 7499) {
      return '$5000-$7499';
    } else if (deductible > 7499) {
      return '$7500 and above';
    }
  }

  sendModalEvent(modal_action:string, modal_name:string){
    this.datalayerService.sendCustomEvent('modal', {
      modal_action,
      modal_name,
      modal_location: this.router.url,
      modal_impression: modal_action === 'open' ? 1 : undefined
    });
  }
}
