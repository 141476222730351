import { Pipe, PipeTransform } from '@angular/core';
import { Filter } from '../models';
@Pipe({
  name: 'excludeFilterName'
})
export class ExcludeFilterNamePipe implements PipeTransform {

  transform(value: Filter[], filterName: string): any {
    return value.filter(v => v.filterName.toUpperCase() !== filterName.toUpperCase());
  }

}
