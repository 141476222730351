import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchContainerComponent } from './search/search-container/search-container.component';
import { ResultsContainerComponent } from './results/results-container/results-container.component';
import { ViewFileComponent } from './results/view-file/view-file.component';
import { UserProfileResolve } from './shared/resolvers/user-profile-resolver';

const routes: Routes = [
  { path: '',   redirectTo: '/search', pathMatch: 'full'},
  { path: 'search', resolve: { userProfile: UserProfileResolve }, pathMatch: 'full', component: SearchContainerComponent, 
    data: {pageEventData: {page_type: 'search', site_section: 'sbccmfinder', site_subcategory: 'search', site_subcategory_depth: '1' }}},
  { path: 'results', resolve: { userProfile: UserProfileResolve }, pathMatch: 'full', component: ResultsContainerComponent,
    data: {pageEventData: {page_type: 'results', site_section: 'sbccmfinder', site_subcategory: 'results', site_subcategory_depth: '2' }}},
  { path: 'view-file', resolve: { userProfile: UserProfileResolve }, pathMatch: 'full', component: ViewFileComponent,
    data: {pageEventData: {page_type: 'view-file', site_section: 'sbccmfinder', site_subcategory: 'view-file', site_subcategory_depth: '3' }}},
  // { path: 'get-file/:fileType/:id', pathMatch: 'full', component: GetFileComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
