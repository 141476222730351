import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SearchResult } from 'src/app/shared/models';
import { DatalayerService } from 'src/app/shared/services/datalayer.service';
import { FinderService } from 'src/app/shared/services/finder.service';

@Component({
  selector: 'app-result-tile',
  templateUrl: './result-tile.component.html',
  styleUrls: ['./result-tile.component.scss']
})
export class ResultTileComponent implements OnInit {
  @Input() result: SearchResult;
  @Input() isPublic: boolean;
  @ViewChild('pdfModal') pdfModal;

  hasFile = false;
  pdfUrl;
  fileError = false;
  planName: string;
  networkTypeAbbrev: string;

  constructor(
    private finderService: FinderService,
    private datalayerService: DatalayerService,
    private router: Router
  ) { }

  ngOnInit() {
    this.planName = `${this.result.productName} ${this.result.planName.replace(new RegExp('SM', 'g'), '').replace(/["'()]/g, '')}`;
    const spaceIndex = this.result.networkType.indexOf(' ');
    this.networkTypeAbbrev = (spaceIndex > -1) ? this.result.networkType.substring(0, spaceIndex) : this.result.networkType;
  }

  openPDF(isSBC: boolean) {
    const documentId = isSBC ? this.result.documentId : this.result.coverageManualDocumentId;
    window.open(`/view-file?docID=${documentId}`, '_blank');
  }

  sendModalEvent(modal_action:string, modal_name:string){
    this.datalayerService.sendCustomEvent('modal', {
      modal_action,
      modal_name,
      modal_location: this.router.url,
      modal_impression: modal_action === 'open' ? 1 : undefined
    });
  }
  

  // TODO: REMOVE ALL BELOW IF NEW TAB WORKS IN PROD
  // getDocument(element: any, isSBC: boolean) {
  //   this.fileError = false;
  //   this.hasFile = false;
  //   this.pdfModal.show(element);
  //   const newWin = window.open('', '_blank');
  //   newWin.document.write(this.buildPleaseWaitHtml());
  //   newWin.document.title = 'Wellmark Blue Cross and Blue Shield';
  //   const documentId = (isSBC) ? this.result.documentId : +this.result.coverageManualDocumentId;
  //   this.finderService.getDocumentUrl(documentId)
  //     .subscribe(
  //       response => {
  //         newWin.location.href = response.url;
  //         this.pdfModal.hide();
  //       },
  //       error => {
  //         newWin.document.write(this.buildErrorHtml());
  //         this.hasFile = true;
  //         this.fileError = true;
  //       }
  //     );
  // }

  // // getSBC(element) {
  // //   this.fileError = false;
  // //   this.hasFile = false;
  // //   this.pdfModal.show(element);

  // //   this.finderService
  // //     .getDocument(this.result.documentId, true, this.result.productIdHealth, this.result.productIdPharmacy)
  // //     .subscribe(() => {
  // //       this.pdfModal.hide();
  // //     },
  // //     error => {
  // //         this.hasFile = true;
  // //         this.fileError = true;
  // //     });
  // // }

  // getCM(element) {
  //   // this.fileError = false;
  //   // this.hasFile = false;
  //   // this.pdfModal.show(element);
  //   // const newWin = window.open('', '_blank');
  //   // newWin.document.write(this.buildPleaseWaitHtml());
  //   // newWin.document.title = 'Wellmark Blue Cross and Blue Shield';
  //   // this.apiService.getDocument(this.result.documentId)
  //   //   .subscribe(
  //   //     response => {
  //   //       newWin.location.href = response.toString();
  //   //       this.pdfModal.hide();
  //   //     },
  //   //     error => {
  //   //       newWin.document.write(this.buildErrorHtml());
  //   //       this.hasFile = true;
  //   //       this.fileError = true;
  //   //     }
  //   //   )
  // }

  // buildPleaseWaitHtml(): string {
  //   // tslint:disable-next-line:max-line-length
  //   return '<div><img src="https://www.wellmark.com/includes/images/ajax-loader.gif" style="margin-left: 450px; margin-top: 350px; position: absolute;" alt="Loading, please wait"><br><p style="margin-left: 350px; margin-top: 370px; position: absolute;">Your document is loading. Please wait.</p></div>';
  // }

  // buildErrorHtml(): string {
  //   return '<div><p>An error occurred.  Please close this tab or window and try again.</p></div>';
  // }

}
