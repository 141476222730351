import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone'

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: string, format: string = ''): string {
		if (!value || value === '') {
			return ''
		} else {
				return moment.utc(value).format(format);
		}
	}
}

