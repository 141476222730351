import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { InputMaskModule } from 'primeng/inputmask';

import {
  WellmarkHttpModule,
  WellmarkStartupService
} from '@wellmark/wm-lib-http';

import {
  WellmarkBannerModule,
  WellmarkAccordionModule,
  WellmarkRadioModule,
  WellmarkCheckboxModule,
  WellmarkCardModule,
  WellmarkFooterModule,
  WellmarkNavbarModule,
  WellmarkFieldModule,
  WellmarkServiceModule,
  WellmarkAlertModule,
  WellmarkPopoverModule,
  WellmarkPipesModule,
  WellmarkLinkPopoverModule,
  WellmarkModalModule,
  WellmarkSpinnerModule,
  WellmarkSelectModule,
  WellmarkPaginationModule
} from '@wellmark/wm-lib-ux';

import { RootStoreModule } from './root-store';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { InternalSearchComponent } from './search/internal-search/internal-search.component';
import { PublicSearchComponent } from './search/public-search/public-search.component';
import { ResultsContainerComponent } from './results/results-container/results-container.component';
import { SearchContainerComponent } from './search/search-container/search-container.component';
import { WellmarkIdSearchComponent } from './search/wellmark-id-search/wellmark-id-search.component';
import { ResultTileComponent } from './results/result-tile/result-tile.component';
import { ResultFilterComponent } from './results/result-filter/result-filter.component';
import { SearchFilterComponent } from './search/search-filter/search-filter.component';
import { ViewFileComponent } from './results/view-file/view-file.component';

export function startupServiceFactory(startupService: WellmarkStartupService): () => Promise<any[]> {
  return () => {
    return startupService.load();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    InternalSearchComponent,
    PublicSearchComponent,
    ResultsContainerComponent,
    SearchContainerComponent,
    WellmarkIdSearchComponent,
    ResultTileComponent,
    ResultFilterComponent,
    SearchFilterComponent,
    ViewFileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    InputMaskModule,
    RootStoreModule,
    WellmarkHttpModule.forRoot({
      config: {
        securityMode: environment.securityMode,
        enterpriseSecurityUrl: environment.jwtApiUrl,
        jwtString: environment.jwtString
      }
    }),
    SharedModule,
    WellmarkBannerModule,
    WellmarkAccordionModule,
    WellmarkRadioModule,
    WellmarkCheckboxModule,
    WellmarkCardModule,
    WellmarkFooterModule,
    WellmarkNavbarModule,
    WellmarkFieldModule,
    WellmarkAlertModule,
    WellmarkPopoverModule.forRoot(),
    WellmarkPipesModule,
    WellmarkLinkPopoverModule,
    WellmarkModalModule,
    WellmarkSpinnerModule,
    WellmarkSelectModule,
    WellmarkPaginationModule,
    WellmarkServiceModule.forRoot()
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: startupServiceFactory,
    deps: [WellmarkStartupService],
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
