import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { UserProfileService } from '.././../shared/services/user-profile.service';
import { UserProfile } from '../models/index';
import { Observable, isObservable } from 'rxjs';

@Injectable()
export class UserProfileResolve implements Resolve<UserProfile>  {

  public currentUserProfile: Observable<UserProfile> | UserProfile;
  constructor(private userProfileService: UserProfileService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<UserProfile> | UserProfile {

    if (typeof this.currentUserProfile === 'undefined' || isObservable(this.currentUserProfile)) {
      this.currentUserProfile = this.userProfileService.getUserProfile();
    }

    return this.currentUserProfile;
  }
}
