import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { throwError as observableThrowError, of as observableOf, Observable, Subject, throwError, BehaviorSubject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import saveAs from 'save-as';

import { WellmarkHttpService } from '@wellmark/wm-lib-http';
import {
  User,
  DistinctFilter,
  SearchResult,
  SearchRequest,
  GeneralSearchRequest,
  ProductIdSearchRequest,
  WellmarkIdSearchRequest,
  FileSearch
} from '../models';

import { environment } from 'src/environments/environment';
import { SearchFacade } from '../../root-store/search-store/search-store.facade';

@Injectable({
  providedIn: 'root'
})
export class FinderService {
  apiUrl = environment.apiUrl;
  enterpriseSecurityUrl = environment.jwtApiUrl;
  user: User;
  constructor(
    private http: WellmarkHttpService,
    private titleService: Title,
    private searchState: SearchFacade,
    private detectionService: DeviceDetectorService
  ) {
    this.searchState.user$.subscribe((user: User) => this.user = user);
  }

  buildUser(): Observable<User> {
    const jwtUrl = `${this.enterpriseSecurityUrl}auth/checkauth`;

    return this.http
      .get<User>(jwtUrl)
      .pipe(
        map((user: User) => {
          if (user && user.firstName) {
            user.isProducer = (user.orgType === 'wsAgent' || user.orgType === 'wsBroker' || user.orgType === 'wsNotSet');
          } else {
            user.isProducer = false;
          }
          this.searchState.updateUser(user);
          return user;
        }),
        catchError(error => {
          const user: User = {
            isProducer: false
          };
          this.searchState.updateUser(user);
          return observableOf(user);
        })
      );
  }

  getFilters(): Observable<DistinctFilter[]> {
    const url = `${this.apiUrl}benefits/filters`;

    return this.http
      .get<DistinctFilter[]>(url)
      .pipe(
        map(resp => resp),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  getResults(request: SearchRequest): Observable<SearchResult[]> {
    if (!_.isNil(request.searchType)) {
      switch (request.searchType) {
        case 'C':
          return this.getProductIdResults(request.productIdSearch);
        case 'W':
          return this.getWellmarkIdResults(request.wellmarkSearch);
        default:
          return this.getGeneralResults(request);
      }
    }
  }

  getProductIdResults(request: ProductIdSearchRequest): Observable<SearchResult[]> {
    const url = `${environment.apiUrl}benefits/coverage-code-search` +
      `?productIdHealth=${request.health}` +
      `${(_.isNil(request.drug) ? '' : `&productIdPharmacy=${request.drug}`)}`;

    return this.http
      .get<SearchResult[]>(url)
      .pipe(
        map(resp => resp),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  getWellmarkIdResults(request: WellmarkIdSearchRequest): Observable<SearchResult[]> {
    const formattedDOB = moment(request.dob, 'MM/DD/YYYY', true).format('YYYY-MM-DD');
    const url = `${environment.apiUrl}benefits/wid-search` +
      `?wid=${request.wellmarkId}` +
      `&firstName=${request.firstName}` +
      `&lastName=${request.lastName}` +
      `&dob=${formattedDOB}`;

    return this.http
      .get<SearchResult[]>(url)
      .pipe(
        map(resp => resp),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  getGeneralResults(request: SearchRequest): Observable<SearchResult[]> {
    if (this.user.isProducer) {
      return this.getGeneralInternalResults(request.generalSearch);
    } else {
      return this.getGeneralPublicResults(request.generalSearch);
    }
  }

  getGeneralPublicResults(request: GeneralSearchRequest): Observable<SearchResult[]> {
    const url = `${environment.apiUrl}benefits/general-search` +
      `?isPublic=true` +
      `&state=${request.coverageState}` +
      `&insuranceCategory=${request.insuranceCategory}` +
      `${(_.isNil(request.insuranceType) ? '' : `&insuranceType=${request.insuranceType}`)}`;

    return this.http
      .get<SearchResult[]>(url)
      .pipe(
        map(resp => resp),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  getGeneralInternalResults(request: GeneralSearchRequest): Observable<SearchResult[]> {
    const url = `${environment.apiUrl}benefits/general-search` +
      `?isPublic=false` +
      `&state=${request.coverageState}` +
      `&marketSegment=${request.marketSegment}` +
      `${(_.isNil(request.networkType) ? '' : `&networkType=${request.networkType.join(',')}`)}` +
      `${(_.isNil(request.individualDeductible) ? '' : `&individualDeductible=${request.individualDeductible.join(',')}`)}` +
      `${(_.isNil(request.productName) ? '' : `&productName=${request.productName.join(',')}`)}` +
      `${(_.isNil(request.coverageStartYear) ? '' : `&startYear=${request.coverageStartYear.join(',')}`)}` +
      `${(_.isNil(request.acaMetallicTier) ? '' : `&acaMetallicTier=${request.acaMetallicTier.join(',')}`)}` +
      `${(_.isNil(request.grandfatheredStatus) ? '' : `&grandfatheredPlan=${request.grandfatheredStatus.join(',')}`)}` +
      `${(_.isNil(request.exchangePlan) ? '' : `&exchangePlan=${request.exchangePlan.join(',')}`)}`;

    return this.http
      .get<SearchResult[]>(url)
      .pipe(
        map(resp => resp),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  getDocumentUrl(kovisId: number): Observable<{ url: string }> {
    const url = `${environment.apiUrl}benefits/document-url?kovisDocumentId=${kovisId}`;

    return this.http
      .get<{url: string}>(url)
      .pipe(
        map(resp => resp),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  getDocument(kovisId: number, isSBC: boolean, health: string, drug?: string): Observable<any> {
    const url = `${environment.apiUrl}benefits/document?kovisDocumentId=${kovisId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        responseType: 'blob',
        Accept: 'application/pdf'
      }),
      responseType: 'blob' as 'blob'
    };

    return this.http
      .get(url, httpOptions)
      .pipe(
        map(
          (resp: any) => {
            const fileName = `${health}${(drug ? `_${drug}` : '')}_${isSBC ? 'SBC' : 'CM'}.pdf`;
            this.saveFile(resp, fileName);
          }),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  getDirectDocumentUrl(request: FileSearch): Observable<{ url: string }> {
    const url = `${environment.apiUrl}benefits/file-url` +
      `?fileType=${request.fileType}` +
      `${(_.isNil(request.year) ? '' : `&year=${request.year}`)}` +
      `${(_.isNil(request.productIdHealth) ? '' : `&productIdHealth=${request.productIdHealth}`)}` +
      `${(_.isNil(request.productIdPharmacy) ? '' : `&productIdPharmacy=${request.productIdPharmacy}`)}` +
      `${(_.isNil(request.sbcId) ? '' : `&sbcId=${request.sbcId}`)}` +
      `${(_.isNil(request.documentId) ? '' : `&documentId=${request.documentId}`)}`;

    return this.http
      .get<{url: string}>(url)
      .pipe(
        map(resp => resp),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  getDirectDocument(request: FileSearch): Observable<any> {
    const url = `${environment.apiUrl}benefits/file` +
      `?fileType=${request.fileType}` +
      `${(_.isNil(request.year) ? '' : `&year=${request.year}`)}` +
      `${(_.isNil(request.productIdHealth) ? '' : `&productIdHealth=${request.productIdHealth}`)}` +
      `${(_.isNil(request.productIdPharmacy) ? '' : `&productIdPharmacy=${request.productIdPharmacy}`)}` +
      `${(_.isNil(request.sbcId) ? '' : `&sbcId=${request.sbcId}`)}` +
      `${(_.isNil(request.documentId) ? '' : `&documentId=${request.documentId}`)}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        responseType: 'blob',
        Accept: 'application/pdf'
      }),
      responseType: 'blob' as 'blob'
    };

    return this.http
      .get(url, httpOptions)
      .pipe(
        map(
          (resp: any) => {
            const fileName = `${request.fileType}.pdf`;
            this.saveFile(resp, fileName);
          }),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  private saveFile(resp: any, fileName: string) {
    const deviceInfo = this.detectionService.getDeviceInfo();
    const blob = new Blob([resp], { type: 'application/pdf' });
    if (deviceInfo.device.toUpperCase() === 'IPHONE' || deviceInfo.device.toUpperCase() === 'IPAD') {
      const reader = new FileReader();
      reader.onload = (e) => {
        window.location.href = reader.result as string;
      };
      reader.readAsDataURL(blob);
    } else {
      saveAs(blob, fileName);
    }
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  private handleError(error: Response) {
    // logging to the console for now
    console.log(error);
    return observableThrowError(error || 'Server error');
  }
}
