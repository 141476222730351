import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Optional } from '@angular/core';

import * as _ from 'lodash';
import { Filter } from '../../shared/models';

@Component({
  selector: 'app-result-filter',
  templateUrl: './result-filter.component.html',
  styleUrls: ['./result-filter.component.scss']
})

export class ResultFilterComponent implements OnInit {
  @Input() filterObject: Filter;
  @Input() isOnResultScreen = false;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('accordion') accordion;
  camelName = '';
  constructor() { }

  ngOnInit() {}

  getCheckId(i: number) {
    return 'chk'.concat(this.filterObject.filterName.replace(' ', ''), (i.toString()));
  }

  getSelectedValues() {
    let selectedValues = '';
    this.filterObject.filterOptions.filter(f => f.selected).forEach(filter => {
      selectedValues = selectedValues + filter.filterValue + ', ';
    });

    return selectedValues.replace(/(^, )|(, $)/g, '');
  }

  updateResults() {
    if (this.isOnResultScreen) {
      this.valueChanged.emit('');
    }
  }

  open() {
    this.accordion.show = true;
  }

  close() {
    this.accordion.show = false;
  }
}
