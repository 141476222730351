import { Component, OnInit, HostListener, ViewContainerRef, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { FinderService } from './shared/services/finder.service';
import { User, UserProfile } from './shared/models';
import { environment } from 'src/environments/environment';
import { DatalayerService } from './shared/services/datalayer.service';
import * as sharedModels from './shared/models/index';

export function findParent(tagName, el) {
  if ((el.nodeName || el.tagName).toLowerCase() === tagName.toLowerCase()) {
    return el;
  }

  let targetEl = el;
  // tslint:disable-next-line:no-conditional-assignment
  while ((targetEl = targetEl.parentNode)) {
    if ((targetEl.nodeName || targetEl.tagName).toLowerCase() === tagName.toLowerCase()) {
      return el;
    }
  }

  return null;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  env = environment.envName;
  user: User;
  userProfile: UserProfile;

  constructor(
    public finderService: FinderService,
    private renderer: Renderer2,
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    private route: ActivatedRoute,
    private datalayerService: DatalayerService
  ) { }


  @HostListener('click', ['$event'])
  private handleAnchorClicks(event: any) {
    // global listener to patch Angular's mishandling of non-routed anchor links
    if (!document || !window) { return true; }
    let elm = event.target;
    while (elm && elm.tagName !== 'A') {
      // tslint:disable-next-line:no-conditional-assignment
      if (elm === (this.viewContainerRef.element.nativeElement) || !(elm = elm.parentElement)) {
        break;
      }
    }
    if (!elm || elm.tagName !== 'A' || event.ctrlKey || event.metaKey || event.shiftKey) {
      return true;
    }
    const hval = elm.getAttribute('href');
    if (_.isNil(hval) || hval === '#') {
      event.preventDefault();
    }
  }

  ngOnInit() {
    this.finderService
      .buildUser()
      .subscribe((user: User) => this.user = user);
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntil(this.destroy$),
    ).subscribe((event) => {
      this.renderer.setProperty(document.body, 'scrollTop', 0);
      this.skipToId('#app-start');
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.route?.firstChild?.snapshot?.data?.pageEventData) {
        const dl = this.route.firstChild.snapshot.data.pageEventData;  
        if (dl['page_type'] === 'view-file'){
          dl['site_subcategory'] = this.router.url.split('?')[1];
        }
        this.datalayerService.sendDatalayerEvent('page_load', dl);
      }
    });

    //CTA event code
		document.addEventListener('click', (event) => {
			if (event.target instanceof HTMLElement) {
				if (event.target.attributes.getNamedItem('class') && event.target.attributes.getNamedItem('class').value.includes('trackCTA')){
					let elemType = event.target.localName.toLowerCase() === 'button' ?
						'button' : 'link';
          let altAttribute = event.target.attributes.getNamedItem('alt');
          let href = event.target.attributes.getNamedItem('href');

         
          const ctaEvent: sharedModels.CTAEvent = {
            cta_element_type: elemType,
            cta_click_text: event.target.innerText,
            cta_click_image_alt_text: altAttribute ? altAttribute.value : undefined,
            cta_type: "user engagement"
          }
					this.datalayerService.sendCtaEvent(ctaEvent, href ? href.value : undefined);
			}
		}});
}

  skipToId(id: string = '#content-start') {
    const element = this.renderer.selectRootElement(id);
    element.focus();
  }
}
