import { Action } from '@ngrx/store';
import { SearchRequest, SearchResult, User } from 'src/app/shared/models';

export enum SearchActionTypes {
    IS_LOADING = '[Search] Is  Loading',
    LOADING_COMPLETE = '[Search] Loading Complete',
    SET_ERROR = '[Search] Setting Error',
    SAVE_REQUEST = '[Search] Save Search Request',
    REQUEST_SAVED = '[Search] Search Save Request Success',
    SEARCH = '[Search] Request Search Data',
    SEARCH_SUCCESS = '[Search] Search Data Success',
    SET_USER = '[Search] Set User',
    CLEAR_SEARCH = '[Search] Clear Search',
    CLEAR_RESULTS = '[Search] Clear Results'
}

// Application Level
export class IsLoadingAction implements Action {
    readonly type = SearchActionTypes.IS_LOADING;

    constructor() { }
 }

export class LoadingCompleteAction implements Action {
    readonly type = SearchActionTypes.LOADING_COMPLETE;

    constructor() {}
 }

export class ClearSearchAction implements Action {
    readonly type = SearchActionTypes.CLEAR_SEARCH;

    constructor() {}
 }

export class ClearResultsAction implements Action {
    readonly type = SearchActionTypes.CLEAR_RESULTS;

    constructor() {}
 }

export class FailureAction implements Action {
    readonly type = SearchActionTypes.SET_ERROR;

    constructor(public payload: { error: string }) {}
 }

export class SetUserAction implements Action {
    readonly type = SearchActionTypes.SET_USER;

    constructor(public payload: { user: User }) {}
 }

// Search
export class SaveRequestAction implements Action {
    readonly type = SearchActionTypes.SAVE_REQUEST;

    constructor(public payload: { request: SearchRequest }) {}
 }


export class RequestSaveSuccessAction implements Action {
    readonly type = SearchActionTypes.REQUEST_SAVED;

    constructor() { }
}


export class SearchAction implements Action {
    readonly type = SearchActionTypes.SEARCH;

    constructor() {}
 }


export class SearchSuccessAction implements Action {
    readonly type = SearchActionTypes.SEARCH_SUCCESS;

    constructor(public payload: { response: SearchResult[] }) {}
 }


export type Actions = IsLoadingAction |
LoadingCompleteAction |
FailureAction |
SetUserAction|
SaveRequestAction |
RequestSaveSuccessAction |
SearchAction |
SearchSuccessAction |
ClearResultsAction |
ClearSearchAction;
