import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as SearchStoreActions from './actions';
import * as SearchStoreSelectors from './selectors';
import * as SearchStoreState from './state';
import { SearchRequest, SearchResult, User } from 'src/app/shared/models';


@Injectable()
export class SearchFacade {
    isLoading$ = this.store$.select(SearchStoreSelectors.selectIsLoading);
    searchError$ = this.store$.select(SearchStoreSelectors.selectError);
    request$ = this.store$.select(SearchStoreSelectors.selectSearchRequest);
    response$ = this.store$.select(SearchStoreSelectors.selectSearchResponse);
    user$ = this.store$.select(SearchStoreSelectors.selectUser);

    constructor(private store$: Store<SearchStoreState.State>) { }

    isLoading() {
        this.store$.dispatch(new SearchStoreActions.IsLoadingAction());
    }

    loadingComplete() {
        this.store$.dispatch(new SearchStoreActions.LoadingCompleteAction());
    }

    search() {
        this.store$.dispatch(new SearchStoreActions.SearchAction());
    }

    clearSearch() {
        this.store$.dispatch(new SearchStoreActions.ClearSearchAction());
    }

    clearResults() {
        this.store$.dispatch(new SearchStoreActions.ClearResultsAction());
    }

    updateUser(user: User) {
        this.store$.dispatch(new SearchStoreActions.SetUserAction({ user }));
    }

    updateRequest(request: SearchRequest) {
        this.store$.dispatch(new SearchStoreActions.SaveRequestAction({ request }));
    }

    updateResponse(response: SearchResult[]) {
        this.store$.dispatch(new SearchStoreActions.SearchSuccessAction({ response }));
    }

    hasError(error: string) {
        this.store$.dispatch(new SearchStoreActions.FailureAction({ error }));
    }
}
