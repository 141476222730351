<div class="panel panel-default">
  <div class="panel-heading text-left">
    <div class="panel-heading-inner">
      <h2>{{ planName }}</h2>
    </div>
  </div>
  <div class="panel-body">
    <div class="row">
      <div class="col-md-3 col-12">
        <p class="field-header">Coverage Dates</p>
        <p class="mar-btm-10">{{ result?.effectiveDate | formatDate: 'MM/DD/YYYY' }} -
          {{ result?.endDate | formatDate: 'MM/DD/YYYY' }}</p>
        <ng-container *ngIf="!isPublic">
          <p class="field-header">Network Type</p>
          <p class="mar-btm-10">{{ networkTypeAbbrev }}</p>
        </ng-container>
        <p *ngIf="result?.grandfatheredPlan === 'Yes'">
          <a href="#"
             (click)="gfModal.show($event.target);sendModalEvent('open', 'grandfathered_modal');"
             class="link-condensed link-icon">
            <span class="link-text">Grandfathered</span>
            <span class="sr-only"> This will open a modal to for more information on your grandfathered
              {{ result.planName + ' ' + result.startYear }} plan</span>
            <span class="icon-info"
                  aria-hidden="true"></span>
          </a>
          <p *ngIf="result?.exchangePlan === 'Yes'"
             class="on-exchange">On Exchange</p>
      </div>
      <div class="col-md-3 col-12">
        <p class="field-header">Product ID (health)</p>
        <p class="mar-btm-10">{{ result?.productIdHealth }}</p>
        <ng-container *ngIf="result?.productIdPharmacy?.length > 0">
          <p class="field-header">Product ID (drug)</p>
          <p class="mar-btm-10">{{ result?.productIdPharmacy }}</p>
        </ng-container>
        <ng-container *ngIf="!isPublic && result?.acaMetallicTier?.length > 0">
          <p class="field-header">ACA Metallic Tier</p>
          <p>{{ result?.acaMetallicTier  }}</p>
        </ng-container>
      </div>
      <div class="col-md-3 col-12">
        <p class="field-header">Individual Deductible</p>
        <p class="mar-btm-10">{{ result?.individualDeductible | currency:'USD': 'symbol': '1.0-0' }}</p>
        <p class="field-header">Other Deductible</p>
        <p class="mar-btm-10">{{ result?.otherAmt | currency:'USD': 'symbol': '1.0-0' }}</p>
      </div>
      <div class="col-md-3 col-12">
        <p *ngIf="result?.documentId > 0"
           class="mar-btm-10">
          <a [routerLink]="['/view-file']"
             [queryParams]="{ docID: result.documentId }"
             target="_blank"
             class="link-condensed link-icon">
            <span class="link-text trackCTA">SBC</span>
            <span class="sr-only"> for {{ result.planName + ' ' + result.startYear }} this will open a tab/window to
              view/download a PDF File</span>
            <span class="icon-file-pdf"
                  aria-hidden="true"></span>
          </a>
        </p>
        <p *ngIf="result?.documentId === 0"
           class="mar-btm-10">
          <a href="#"
             (click)="sbcModal.show($event.target);sendModalEvent('open', 'sbc_modal');"
             class="link-condensed link-icon">
            <span class="link-text">SBC Not Available</span>
            <span class="sr-only"> This will open a modal to for more information on your missing SBC for
              {{ result.planName + ' ' + result.startYear }}</span>
            <span class="icon-info"
                  aria-hidden="true"></span>
          </a>
        </p>
        <p *ngIf="result?.coverageManualDocumentId && result.coverageManualDocumentId.length > 0">
          <a [routerLink]="['/view-file']"
             [queryParams]="{ docID: result.coverageManualDocumentId }"
             target="_blank"
             class="link-condensed link-icon">
            <span class="link-text trackCTA">Coverage Manual</span>
            <span class="sr-only"> for {{ result.planName + ' ' + result.startYear }} this will open a new tab/window to
              view/download a PDF File</span>
            <span class="icon-file-pdf"
                  aria-hidden="true"></span>
          </a>
        </p>
        <p class="position-anchor"
           *ngIf="!result?.coverageManualDocumentId || result.coverageManualDocumentId.length === 0">
          <a href="#"
             (click)="cmModal.show($event.target);sendModalEvent('open','coverage_manual_modal');"
             class="link-condensed link-icon">
            <span class="link-text">Coverage Manual Not Available</span>
            <span class="sr-only"> This will open a modal to for more information on your missing coverage manual for
              {{ result.planName + ' ' + result.startYear }}</span>
            <span class="icon-info"
                  aria-hidden="true"></span>
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
<!-- PDF Modal -->
<div class="modal dark fade"
     wmModal
     #pdfModal="wm-modal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="pdf-modal"
     aria-hidden="true"
     [focusEl]="'button.close'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button"
                class="close pull-right"
                (click)="pdfModal.hide();sendModalEvent('close','pdf_modal');"
                aria-label="Close">
          <span aria-hidden="true">
            <span class="close-text">Close</span>
            <span class="icon-wmkCircleClose"></span>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <h3 id="pdf-modal"
            class="sr-only">Your Document</h3>
        <p *ngIf="hasFile && fileError">
          An error occurred creating your PDF. Please try again.
        </p>
        <wm-spinner *ngIf=!this.hasFile></wm-spinner>
      </div>
    </div>
  </div>
</div>
<!-- Grandfather Modal -->
<div class="modal dark fade"
     wmModal
     #gfModal="wm-modal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="gf-modal"
     aria-hidden="true"
     [focusEl]="'button.close'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button"
                class="close pull-right"
                (click)="gfModal.hide();sendModalEvent('close','grandfathered_modal');"
                aria-label="Close">
          <span aria-hidden="true">
            <span class="close-text">Close</span>
            <span class="icon-wmkCircleClose"></span>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <h3 id="sbc-modal">Grandfathered</h3>
        <p class="mar-top-15">
          Individual or family plans purchased prior to March 2010.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- SBC Modal -->
<div class="modal dark fade"
     wmModal
     #sbcModal="wm-modal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="sbc-modal"
     aria-hidden="true"
     [focusEl]="'button.close'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button"
                class="close pull-right"
                (click)="sbcModal.hide();;sendModalEvent('close','sbc_modal');"
                aria-label="Close">
          <span aria-hidden="true">
            <span class="close-text">Close</span>
            <span class="icon-wmkCircleClose"></span>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <h3 id="sbc-modal">SBC Not Available</h3>
        <p class="mar-top-15">
          If your employer provides your insurance, please contact them to obtain your SBC.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- Coverage Manual Modal -->
<div class="modal dark fade"
     wmModal
     #cmModal="wm-modal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="cm-missing-modal"
     aria-hidden="true"
     [focusEl]="'button.close'">
  <div class="modal-dialog">
    <div class="modal-content large">
      <div class="modal-header">
        <button type="button"
                class="close pull-right"
                (click)="cmModal.hide();sendModalEvent('close','coverage_manual_modal');"
                aria-label="Close">
          <span aria-hidden="true">
            <span class="close-text">Close</span>
            <span class="icon-wmkCircleClose"></span>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="h2 mar-btm-20"
            id="cm-missing-modal">Coverage Manual Not Available</h3>
        <p class="mar-btm-20">Please refer to the sample coverage manuals or call 800-524-9242 for assistance. These
          sample manuals describe
          the benefits and limitations of a typical large employer health insurance plan.</p>
        <div class="list-samples-container mar-btm-20">
          <p>For Iowa:</p>
          <ul class="bullet-list">
            <li>
              <a href="https://www.wellmark.com/CoverageManual/PDFs/Sample-Alliance-Select.pdf"
                 class="link-icon-right"
                 target="_blank">
                <span class="link-text">Preferred Provider Organization (PPO) Sample</span>
                <span class="sr-only"> This will open in a new window or tab</span>
                <span class="icon-file-pdf"
                      aria-hidden="true"></span>
              </a>
            </li>
            <li>
              <a href="https://www.wellmark.com/CoverageManual/PDFs/Sample-Blue-Advantage.pdf"
                 class="link-icon-right"
                 target="_blank">
                <span class="link-text">Health Maintenance Organization (HMO) Sample</span>
                <span class="sr-only"> This will open in a new window or tab</span>
                <span class="icon-file-pdf"
                      aria-hidden="true"></span>
              </a>
            </li>
            <li>
              <a href="https://www.wellmark.com/CoverageManual/PDFs/Sample-Blue-Choice.pdf"
                 class="link-icon-right"
                 target="_blank">
                <span class="link-text">Point of Service (POS) Sample</span>
                <span class="sr-only"> This will open in a new window or tab</span>
                <span class="icon-file-pdf"
                      aria-hidden="true"></span>
              </a>
            </li>
          </ul>
        </div>
        <div class="list-samples-container mar-btm-20">
          <p>For South Dakota:</p>
          <ul class="bullet-list">
            <li>
              <a href="https://www.wellmark.com/CoverageManual/PDFs/Sample-Blue-Select.pdf"
                 class="link-icon-right"
                 target="_blank">
                <span class="link-text">Preferred Provider Organization (PPO) Sample</span>
                <span class="sr-only"> This will open in a new window or tab</span>
                <span class="icon-file-pdf"
                      aria-hidden="true"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>