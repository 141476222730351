<wm-accordion-tab #accordion header="{{ filterObject?.filterName }}" [subheader]="getSelectedValues()">
  <div class="checkbox-wrapper" *ngFor="let option of filterObject.filterOptions; let i = index">
      <wm-checkbox 
          [value]="option.filterValue"
          [formControl]="parent.form.controls[camelName]"
          [label]="option.filterValue"
          [inputId]="getCheckId(i)"
          >
      </wm-checkbox>            
  </div>
</wm-accordion-tab>