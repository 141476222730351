<form [formGroup]="form">
  <wm-alert *ngIf="(errors$ | async) as errors"
            [errors]="errors"
            (clickError)="setFocus($event)"> </wm-alert>
  <wm-card styleClass="mar-btm-15">
    <div class="row">
      <div class="col-12">
        <wm-field>
          <h2 class="mar-btm-15"
              id="lblSearchBy">*How would you like to search?</h2>
          <div role="radiogroup"
               aria-labelledby="lblSearchBy">
            <wm-radio name="searchBy"
                      formControlName="searchBy"
                      value="C"
                      label="Product IDs"
                      inputId="searchBy">
            </wm-radio>
            <wm-radio name="searchBy"
                      formControlName="searchBy"
                      value="W"
                      label="Wellmark ID Number"
                      inputId="searchBy1">
            </wm-radio>
            <wm-radio name="searchBy"
                      formControlName="searchBy"
                      value="G"
                      label="General Search"
                      inputId="searchBy2">
            </wm-radio>
          </div>
        </wm-field>
      </div>
    </div>
  </wm-card>
  <app-wellmark-id-search *ngIf="form.get('searchBy').value === 'W'"
                          [parentGroup]="form.get('id')">
  </app-wellmark-id-search>
  <wm-card *ngIf="form.get('productId').enabled"
           [formGroup]="form.get('productId')"
           styleClass="mar-btm-15">
    <div class="row">
      <div class="col-md-5 col-12">
        <h2 class="mar-btm-15">Product ID</h2>
        <wm-field>
          <label for="health"
                 class="input-label">*Product ID (health)</label>
          <input formControlName="health"
                 class="wm-input"
                 id="health">
        </wm-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 col-12">
        <wm-field>
          <label for="drug"
                 class="input-label">Product ID (drug)</label>
          <input formControlName="drug"
                 class="wm-input"
                 id="drug">
        </wm-field>
      </div>
    </div>
  </wm-card>
  <wm-card *ngIf="form.get('general').enabled"
           [formGroup]="form.get('general')"
           styleClass="mar-btm-15">
    <div class="row">
      <div class="col-12">
        <h2 class="mar-btm-15">General Search</h2>
        <wm-field>
          <label id="lblState"
                 class="input-label">*State</label>
          <div role="radiogroup"
               aria-labelledby="lblState">
            <wm-radio name="coverageState"
                      formControlName="coverageState"
                      value="IA"
                      label="Iowa"
                      inputId="coverageState">
            </wm-radio>
            <wm-radio name="coverageState"
                      formControlName="coverageState"
                      value="SD"
                      label="South Dakota"
                      inputId="coverageState1">
            </wm-radio>
          </div>
        </wm-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 col-12">
        <wm-field>
          <label class="input-label"
                 for="marketSegment">*Market Segment</label>
          <wm-select>
            <select id="marketSegment"
                    formControlName="marketSegment"
                    class="wm-input">
              <ng-container *ngFor="let filter of filters | filterName:'Market Segment'">
                <option value=""
                        selected>Select</option>
                <option *ngFor="let option of filter.filterOptions"
                        [value]="option.filterValue">
                  {{ option.filterValue }}</option>
              </ng-container>
            </select>
          </wm-select>
        </wm-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button id="advancedSearchToggleBtn"
                class="wm-btn primary advanced-search-btn"
                (click)="showAdvancedSearch = !showAdvancedSearch"
                [attr.aria-expanded]="showAdvancedSearch"
                aria-controls="advancedSearchContent">
          <span *ngIf="!showAdvancedSearch">Show Advanced Search</span>
          <span *ngIf="showAdvancedSearch">Hide Advanced Search</span>
        </button>
      </div>
    </div>
    <ng-container>
      <div class="row">
        <div class="col-md-6 col-12">
          <div *ngIf="showAdvancedSearch"
               id="advancedSearchContent"
               class="advanced-search-container"
               [attr.aria-expanded]="showAdvancedSearch"
               [attr.aria-hidden]="!showAdvancedSearch">
            <div class="advanced-search-actions">
              <div class="pull-left">
                <a class="link-condensed clear-link"
                   href="#"
                   (click)="clearCheckFilters()">CLEAR</a>
              </div>
              <div class="pull-right">
                <a href="#"
                   class="link-icon link-icon-right link-condensed expand-all-link"
                   (click)="$event.preventDefault(); toggleAllFilterPanels();">
                  <span class="link-text">{{(showFilterPanels) ? 'Collapse': 'Expand'}} ALL</span>
                  <span class="fa"
                        [ngClass]="{'fa-expand': !showFilterPanels, 'fa-compress': showFilterPanels}"
                        aria-hidden="true"></span>
                </a>
              </div>
            </div>
            <div class="result-filters internal-search-result-filters"
                 *ngIf="filters && filters.length > 0; else spinner">
              <app-search-filter #searchFilter
                                 *ngFor="let filter of filters | excludeFilterName:'Market Segment'; first as isFirst; last as isLast"
                                 [filterObject]="filter"
                                 class="result-filter"
                                 [ngClass]="{'first': isFirst, 'last': isLast}">
              </app-search-filter>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </wm-card>
  <div class="mar-15">
    <button class="wm-btn primary trackCTA"
            (click)="submitForm()"
            type="submit">Search</button>
  </div>
</form>
<ng-template #spinner>
  <wm-spinner></wm-spinner>
</ng-template>