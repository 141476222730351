import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-wellmark-id-search',
  templateUrl: './wellmark-id-search.component.html',
  styleUrls: ['./wellmark-id-search.component.scss']
})
export class WellmarkIdSearchComponent implements OnInit {
  @Input() parentGroup: UntypedFormGroup;
  constructor() { }

  ngOnInit() {
  }

}
