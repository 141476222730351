import { Actions, SearchActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case SearchActionTypes.IS_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case SearchActionTypes.LOADING_COMPLETE: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SearchActionTypes.CLEAR_SEARCH: {
            return {
                ...state,
                request: {}
            };
        }
        case SearchActionTypes.CLEAR_RESULTS: {
            return {
                ...state,
                response: undefined
            };
        }
        case SearchActionTypes.SET_USER: {
            return {
                ...state,
                user: action.payload.user
            };
        }
        case SearchActionTypes.SAVE_REQUEST: {
            return {
                ...state,
                request: action.payload.request
            };
        }
        case SearchActionTypes.REQUEST_SAVED: {
            return {
                ...state,
            };
        }
        case SearchActionTypes.SEARCH_SUCCESS: {
            return {
                ...state,
                error: undefined,
                response: action.payload.response
            };
        }
        case SearchActionTypes.SET_ERROR: {
            return {
                ...state,
                error: action.payload.error
            };
        }
        default: {
            return state;
        }
    }
}
