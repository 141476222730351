import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileResolve } from './resolvers/user-profile-resolver'

import { DeviceDetectorService } from 'ngx-device-detector';

import { ExcludeFilterNamePipe } from './pipes/exlude-filter-name.pipe';
import { FilterNamePipe } from './pipes/filter-name.pipe';
import { FinderService } from './services/finder.service';
import { FormatDatePipe } from './pipes/format-date.pipe';

@NgModule({
    imports: [
      CommonModule
    ],
    declarations: [
      ExcludeFilterNamePipe,
      FilterNamePipe,
      FormatDatePipe
    ],
    exports: [
      ExcludeFilterNamePipe,
      FilterNamePipe,
      FormatDatePipe
    ],
    providers: [
        FinderService,
        DeviceDetectorService,
        UserProfileResolve
    ]
  })
  export class SharedModule { }

