<wm-card [formGroup]="parentGroup" styleClass="mar-btm-15">
  <div class="row">
    <div class="col-12">
      <h2>Wellmark ID Number</h2>
    </div>
  </div>
  <div class="row flex-column-reverse flex-md-row">
    <div class="col-md-6 col-12">
      <div class="row">
        <div class="col-12">
          <wm-field>
            <label for="wellmarkId" class="input-label">
              *Wellmark ID Number<br />
              <span class="help">On your Wellmark ID card, the ID number is below your name. You can also find it on
                your Explanation of
                Benefits (EOB).</span>
            </label>
            <input formControlName="wellmarkId" class="wm-input" id="wellmarkId">
          </wm-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <wm-field>
            <label for="firstName" class="input-label">
              *First Name
            </label>
            <input formControlName="firstName" class="wm-input" id="firstName">
          </wm-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <wm-field>
            <label for="lastName" class="input-label">
              *Last Name
            </label>
            <input  formControlName="lastName" class="wm-input" id="lastName">
          </wm-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <wm-field>
            <label for="dob" class="input-label">
              *Date of Birth<br />
              <span class="help">MM/DD/YYYY</span>
            </label>
            <p-inputMask mask="99/99/9999" inputId="dob" formControlName="dob" [autoClear]="false" type="text"
              styleClass="wm-input">
            </p-inputMask>
          </wm-field>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-12">
        <img class="img-fluid" src="assets/img/BCBSCardArt_2016.png"
        alt="Your Wellmark ID number is listed on the front of your Wellmark ID card." />
    </div>
  </div>
</wm-card>