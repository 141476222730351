import { Pipe, PipeTransform } from '@angular/core';
import { Filter } from '../models';

@Pipe({
  name: 'filterName'
})
export class FilterNamePipe implements PipeTransform {
  transform(value: Filter[], filterName: string): any {
    return value.filter(v => v.filterName.toUpperCase() === filterName.toUpperCase());
  }
}
