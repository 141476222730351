import { Injectable } from '@angular/core';
import { WellmarkHttpService } from '@wellmark/wm-lib-http';
import { map, catchError } from 'rxjs/operators';
import { of as observableOf, Observable } from 'rxjs';
import { UserProfile } from '../models/index';
import { environment } from '../../../environments/environment'


@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  userProfile: UserProfile;
  constructor(private http: WellmarkHttpService) { }

  getUserProfile():Observable<UserProfile> | UserProfile{
    if (this.userProfile) return this.userProfile;
    if (!document.cookie.includes('WellmarkJwt')) {
      this.userProfile = {};
      return observableOf(this.userProfile);
    }
    return this.http.get(environment.profileUrl)
    .pipe(
      map((profile: UserProfile) => {
        this.userProfile = profile;
        return profile;
      }), catchError(() => {
        this.userProfile = {};
        return observableOf(this.userProfile);
      })
    )
  }
}
