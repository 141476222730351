import { Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { FormService, WellmarkError, DateValidator, WellmarkIdValidator } from '@wellmark/wm-lib-ux';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Filter, FilterList, SearchRequest } from '../../shared/models';
import { FinderService } from '../../shared/services/finder.service';
import { ERRORS } from '../../shared/data/error-messages';
import { ResultFilterComponent } from 'src/app/results/result-filter/result-filter.component';
import { SearchFacade } from '../../root-store/';
import { DatalayerService } from '../../shared/services/datalayer.service';
import { FormEvent } from '../../shared/models/datalayer-events/formEvent';

@Component({
  selector: 'app-internal-search',
  templateUrl: './internal-search.component.html',
  styleUrls: ['./internal-search.component.scss']
})
export class InternalSearchComponent implements OnInit {
  @ViewChildren('searchFilter') domFilters: ResultFilterComponent[];

  filterList = FilterList;
  filters: Filter[] = [];
  errors$: Observable<WellmarkError[]>;
  form: UntypedFormGroup;
  errors = ERRORS.internal;
  showAdvancedSearch = false;
  showFilterPanels = false;
  formEventBase: FormEvent = {
    form_category: 'support',
    form_name: 'sbc_coverage_manual_finder'
  };
  private destroy$ = new Subject<void>();
  constructor(
    private formService: FormService,
    private fb: UntypedFormBuilder,
    private finderService: FinderService,
    private searchState: SearchFacade,
    private datalayerService: DatalayerService, 
    private router: Router
  ) {
    this.errors$ = this.formService.errors$;
    this.errors$.subscribe(errorList => {
      for (const err of errorList) {
        this.datalayerService.sendCustomEvent('forms', 
        {
          ...this.formEventBase, 
          form_action: 'error', 
          form_input_error_count: 1, 
          form_error_label: err.errorMessage,
          form_start: 'False'
        });
        this.datalayerService.sendCustomEvent('error_tracking', 
        {
          error_displayed_count:1, 
          error_location: this.router.url,
          error_displayed_text: err.errorMessage
        });
      }
    })
    this.form = this.fb.group({
      searchBy: ['', Validators.required],
      general: this.fb.group({
        coverageState: [null, Validators.required],
        marketSegment: ['', Validators.required]
      }),
      productId: this.fb.group({
        health: ['', Validators.required],
        drug: [undefined]
      }),
      id: this.fb.group({
        wellmarkId: ['', [Validators.required, WellmarkIdValidator.valid]],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        dob: ['', [DateValidator.valid, Validators.required]]
      })
    });
  }

  ngOnInit() {
    this.searchState.clearResults();
    this.finderService.getFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        results => this.buildDistinctFilters(results)
        , error => { }
      );

    this.form.get('searchBy').valueChanges.subscribe(() => this.toggleSearchType());
    this.toggleSearchType();
    this.datalayerService.sendCustomEvent('forms', {...this.formEventBase, form_action: 'start', form_start: 'True'});
  }

  toggleSearchType() {
    this.formService.clearErrors();
    const value = this.form.get('searchBy').value;
    if (value === 'G') {
      this.form.get('general').enable();
      this.form.get('id').disable();
      this.form.get('productId').disable();
    } else if (value === 'W') {
      this.form.get('general').disable();
      this.form.get('id').enable();
      this.form.get('productId').disable();
    } else if (value === 'C') {
      this.form.get('general').disable();
      this.form.get('id').disable();
      this.form.get('productId').enable();
    } else {
      this.form.get('general').disable();
      this.form.get('id').disable();
      this.form.get('productId').disable();
    }
  }

  clearCheckFilters() {
    this.form.get('general').patchValue({
      acaMetallicTier: [],
      coverageStartYear: [],
      coverageState: [],
      exchangePlan: [],
      grandfatheredStatus: [],
      individualDeductible: [],
      networkType: [],
      productName: []
    });
    this.datalayerService.sendCustomEvent('forms', {...this.formEventBase, form_action: 'clear', form_start: 'False'});
  }

  updateCheckValue(filterName: string, i: number) {
    this.filters
      .filter(f => f.filterName === filterName)[0].filterOptions[i].selected = !this.filters
        .filter(f => f.filterName === filterName)[0].filterOptions[i].selected;
  }

  toggleAllFilterPanels() {
    this.showFilterPanels = !this.showFilterPanels;
    for (const result of this.domFilters) {
      if (this.showFilterPanels) {
        result.accordion.show = true;
      } else {
        result.accordion.show = false;
      }
    }
  }

  buildDistinctFilters(filterValues: any) {
    this.filterList.forEach(f => {
      const newFilter: Filter = {
        filterName: f.filterTitle,
        filterOptions: []
      };

      if (f.filterField !== 'individualDeductible') {
        filterValues.forEach(element => {
          if (newFilter.filterOptions.filter(o => o.filterValue.toString() === element[f.filterField].toString()).length === 0) {
            if (element[f.filterField]) {
              newFilter.filterOptions.push({ filterValue: element[f.filterField].toString(), selected: false });
            }
          }
        });
        newFilter.filterOptions.sort((a, b) => {
          return a.filterValue.toUpperCase() < b.filterValue.toUpperCase() ?
            -1 : a.filterValue.toUpperCase() > b.filterValue.toUpperCase() ? 1
              : 0;
        });
      } else {
        newFilter.filterOptions.push(...[
          { filterValue: '$0', selected: false },
          { filterValue: '$50-$999', selected: false },
          { filterValue: '$1000-$1999', selected: false },
          { filterValue: '$2000-$2999', selected: false },
          { filterValue: '$3000-$3999', selected: false },
          { filterValue: '$4000-$4999', selected: false },
          { filterValue: '$5000-$7499', selected: false },
          { filterValue: '$7500 and above', selected: false },
        ]);
      }
      this.filters.push(newFilter);
    });
  }

  submitForm() {
    if (this.formService.validate(this.form, this.errors)) {
      const request: SearchRequest = {
        searchType: this.form.get('searchBy').value
      };
      if (request.searchType === 'C') {
        request.productIdSearch = this.form.get('productId').value;
      } else if (request.searchType === 'W') {
        request.wellmarkSearch = this.form.get('id').value;
      } else {
        request.generalSearch = this.form.get('general').value;
      }
      this.searchState.updateRequest(request);
      this.datalayerService.sendCustomEvent('forms', 
      {
        ...this.formEventBase,
        form_action: 'submit',
        form_submit_count: 1,
        form_start: 'False'
      });
    } 
  }

  setFocus(fieldId: string) {
    this.formService.focusField(fieldId);
  }
}
