import { SearchRequest, SearchResult, User } from '../../shared/models';

export interface State {
    isLoading: boolean;
    error?: any;
    request?: SearchRequest;
    response?: SearchResult[];
    user?: User;
}

export const initialState: State = {
    isLoading: false
};
