import { createSelector, createFeatureSelector } from '@ngrx/store';
import { State } from './state';

export const searchStateSelector = createFeatureSelector('search');

export const selectIsLoading = createSelector(searchStateSelector, (state: State) => state.isLoading);
export const selectError = createSelector(searchStateSelector, (state: State) => state.error);
export const selectUser = createSelector(searchStateSelector, (state: State) => state.user);
export const selectSearchRequest = createSelector(searchStateSelector, (state: State) => state.request);
export const selectSearchResponse = createSelector(searchStateSelector, (state: State) => state.response);
