export const environment = {
  production: true,
  securityMode: 'remote',
  jwtString: '',
  envName: 'PROD',
  applicationUrl: '',
  wellmarkURL: 'https://www.wellmark.com/',
  apiUrl: 'https://enterpriseservices.wellmark.com/coverage-manual-api/v1/',
  jwtApiUrl: 'https://apigw.wellmark.com/enterprise-security-api/v1/',
  signedContentApiUrl: 'https://enterpriseimaging.aws.wellmark.com/enterprise-imaging-api/v1/private-content/signed-url',
  exchangeHealth: 'BSQ|BSR|BSS|SA9|SAB|SAD|BSU|BSV|BSW|U3U|BSZ|U3V|U3W|U3X|U3Y|U42|BT8|U43|SAL|SAM|SAN',
  exchangePharm: '9YL|9YM|9YN|9YQ|9YT|9YU|9YW|9ZA|9ZB|9ZF|9ZG|9ZH|9ZJ|9ZK|9ZL|9ZN|9Y9|9YC|9YE|9YF|9YG',
  profileUrl: 'https://apigw.wellmark.com/enterprise-profile-api/v1/profile'
};
