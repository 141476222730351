import { Component, OnInit, Input, ViewChild, Optional } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, FormGroupDirective, Validators } from '@angular/forms';

import * as _ from 'lodash';
import { Filter } from '../../shared/models';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
  @Input() filterObject: Filter;
  @Input() isOnResultScreen = false;
  @ViewChild('accordion') accordion;
  camelName = '';
  constructor(@Optional() private parent: FormGroupDirective) { }

  ngOnInit() {
    if (this.parent) {
      this.camelName = _.camelCase(this.filterObject.filterName);
      this.parent.form.addControl(this.camelName, new UntypedFormControl());
    }
  }

  getCheckId(i: number) {
    return 'chk'.concat(this.filterObject.filterName.replace(' ', ''), (i.toString()));
  }

  getSelectedValues() {
    let selectedValues = '';
    this.filterObject.filterOptions.filter(f => f.selected).forEach(filter => {
      selectedValues = selectedValues + filter.filterValue + ', ';
    });

    return selectedValues.replace(/(^, )|(, $)/g, '');
  }

  open() {
    this.accordion.show = true;
  }

  close() {
    this.accordion.show = false;
  }
}
