<wm-banner bannerTitleNoTransform="Summary of Benefits and Coverage Manual Finder">
</wm-banner>
<div class="container" *ngIf="user">
  <div class="row">
    <div class="col-12">
      <div *ngIf="!user.isProducer">
        <p class="first">Search for the Summary of Benefits and Coverage (SBC) and Coverage Manual for your plan.</p>
        <br />
        <p>
          <strong>Note to large groups or self-funded plans:</strong> 
          To find your SBC or coverage manual, search using your Wellmark ID Number. If no results are returned, please contact your employer to obtain a copy of your SBC.
        </p>
      </div>
      <p class="ending small">*Required Field</p>
    </div>
  </div>
  <app-internal-search *ngIf="user.isProducer"></app-internal-search>
  <app-public-search *ngIf="!user.isProducer"></app-public-search>
</div>