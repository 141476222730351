import { Injectable } from '@angular/core';
import { BaseDlEvent } from '../models/datalayer-events/baseEvent';
import { CTAEvent } from '../models/datalayer-events/ctaEvent';
import { environment } from '../../../environments/environment';
import { UserProfile } from '../models';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class DatalayerService {
  currentBaseEvent: BaseDlEvent;
  userProfile: UserProfile;
  routeHistory: {href: string, baseEvent: BaseDlEvent}[] = [];
  constructor( private route: ActivatedRoute, private router: Router ) {}   

  sendDatalayerEvent(  event: string, baseEvent: BaseDlEvent, customEvent: any = {}) {
    if (event === 'page_load') {
      this.currentBaseEvent = baseEvent;
      this.routeHistory.push({href: this.router.url, baseEvent: baseEvent});
    }
    
    this.userProfile = this.route.firstChild?.snapshot?.data?.userProfile;
    const dlEvent = {
      ...baseEvent,
      ...customEvent,
      site_name: 'sbc_coverage_manual_finder',
      environment: environment.envName,
      account_login_status: this.userProfile?.id ? 'logged in' : 'logged out',
      user_type: _.get(this.userProfile, 'data.orgType', undefined),
      audience_type: _.get(this.userProfile, 'data.orgType', undefined),
      audience_size: _.get(this.userProfile, 'data.groupSize', undefined),
      user_id: this.userProfile?.data?.userId ? this.userProfile.data.userId.toString() : undefined,
      event
    }
    if ((<any>window).dataLayer) {
      (window as any).dataLayer.push(dlEvent);
    }
  }
  
  sendCustomEvent(event: string, customEvent: any) {
    this.sendDatalayerEvent(event, this.currentBaseEvent, customEvent);
  }

  sendCtaEvent(ctaEvent: CTAEvent, href?: string) {   
      /** 
      * if cta click resulted in router navigation, check to see if link href matches current path
      * if it does, then you need to grab prior route for location value
      * if it is different then it must be external and you should be able to use current router.url
      * if no href,  use current url      
     */
     let cta_location;
     let ctaBaseEvent: BaseDlEvent;
     if (href && this.router.url === href && this.routeHistory.length > 1) {
       cta_location = this.routeHistory[this.routeHistory.length - 2].href;
       ctaBaseEvent = this.routeHistory[this.routeHistory.length -2].baseEvent;
     } else {
       cta_location = this.router.url;
       ctaBaseEvent = this.currentBaseEvent;
     }
     
     const finalCtaEvent: CTAEvent = {...ctaEvent, cta_location}; 
 
     this.sendDatalayerEvent('call_to_action', ctaBaseEvent, finalCtaEvent );
  }
}