import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FormService, WellmarkError, DateValidator, WellmarkIdValidator } from '@wellmark/wm-lib-ux';
import { Observable } from 'rxjs';

import { ERRORS } from '../../shared/data/error-messages';
import { SearchRequest } from 'src/app/shared/models';
import { SearchFacade } from 'src/app/root-store';
import { DatalayerService } from 'src/app/shared/services/datalayer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public-search',
  templateUrl: './public-search.component.html',
  styleUrls: ['./public-search.component.scss']
})
export class PublicSearchComponent implements OnInit {
  errors$: Observable<WellmarkError[]>;
  form: UntypedFormGroup;
  errors = ERRORS.publicSearch;
  formBaseEvent = {
    form_category: 'support',
    form_name: 'sbc_coverage_manual_finder'
  };
  constructor(
    private formService: FormService,
    private fb: UntypedFormBuilder,
    private searchState: SearchFacade,
    private datalayerService: DatalayerService,
    private router: Router
  ) {
    this.form = this.fb.group({
      searchBy: ['', Validators.required],
      general: this.fb.group({
        coverageState: [null, Validators.required],
        insuranceCategory: [null, Validators.required],
        insuranceType: [null, Validators.required]
      }),
      id: this.fb.group({
        wellmarkId: ['', [Validators.required, WellmarkIdValidator.valid]],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        dob: ['', [DateValidator.valid, Validators.required]]
      })
    });
   }

  ngOnInit() {
    this.errors$ = this.formService.errors$;
    this.errors$.subscribe(errorList => {
      for (let err of errorList) {
        this.datalayerService.sendCustomEvent('forms', 
        {
          ...this.formBaseEvent, 
          form_action: 'error', 
          form_error_label: err.errorMessage, 
          form_input_error_count: 1,
          form_start: 'False'
        });
        this.datalayerService.sendCustomEvent('error_tracking', 
        {
          error_displayed_count: 1, 
          error_location: this.router.url,
          error_displayed_text: err.errorMessage
        });
      }
    })
    this.form.get('searchBy').valueChanges.subscribe(() => this.toggleSearchType());
    this.form.get('general.insuranceCategory').valueChanges.subscribe(() => this.toggleInsuranceType());

    this.toggleSearchType();
    this.toggleInsuranceType();
    this.datalayerService.sendCustomEvent('forms', {...this.formBaseEvent, form_action: 'start', form_start: 'True'});
  }

  submitForm() {
    if (this.formService.validate(this.form, this.errors)) {
      const request: SearchRequest = {
        searchType: this.form.get('searchBy').value
      };
      if (request.searchType === 'W') {
        request.wellmarkSearch = this.form.get('id').value;
      } else {
        request.generalSearch = this.form.get('general').value;
      }
      this.searchState.updateRequest(request);
      this.datalayerService.sendCustomEvent('forms', 
      {
        ...this.formBaseEvent,
        form_action: 'submit',
        form_submit_count: 1,
        form_start: 'False'
      });
    }
  }

  toggleSearchType() {
    this.formService.clearErrors();
    const value = this.form.get('searchBy').value;
    if (value === 'G') {
      this.form.get('general').enable();
      this.form.get('id').disable();
      this.form.get('general.insuranceType').disable();
    } else if (value === 'W') {
      this.form.get('general').disable();
      this.form.get('id').enable();
    } else {
      this.form.get('general').disable();
      this.form.get('id').disable();
    }
  }

  toggleInsuranceType() {
    const value = this.form.get('general.insuranceCategory').value;
    if (value === null || value === 'E') {
      this.form.get('general.insuranceType').disable();
    } else {
      this.form.get('general.insuranceType').enable();
    }
  }

  setFocus(fieldId: string) {
    this.formService.focusField(fieldId);
  }

}
