export const ERRORS = {
    publicSearch: {
        searchBy: {
            required: 'Please select how you would like to search.'
        },
        coverageState: {
            required: 'Please select your state.'
        },
        insuranceCategory: {
            required: 'Please select which type of insurance applies to you.'
        },
        insuranceType: {
            required: 'Please select the type of insurance you have.'
        },
        wellmarkId: {
            required: 'Wellmark ID Number is required.',
            invalid: 'You have entered an invalid Wellmark ID.'
        },
        firstName: {
          required: 'Cardholder First Name is required.'
        },
        lastName: {
            required: 'Cardholder Last Name is required.'
        },
        dob: {
            invalid: 'Please enter the cardholder date of birth in the following format: MM/DD/YYYY.'
        }
    },
    internal: {
        searchBy: {
            required: 'Please select how you would like to search.'
        },
        coverageState: {
            required: 'Please select your state.'
        },
        marketSegment: {
            required: 'Please select a market segment.'
        },
        wellmarkId: {
            required: 'Wellmark ID Number is required.',
            invalid: 'You have entered an invalid Wellmark ID.'
        },
        firstName: {
          required: 'Cardholder First Name is required.'
        },
        lastName: {
            required: 'Cardholder Last Name is required.'
        },
        dob: {
            invalid: 'Please enter the cardholder date of birth in the following format: MM/DD/YYYY.'
        },
        health: {
            required: 'Product ID (health) is required.'
        }
    }
};
