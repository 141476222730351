<form [formGroup]="form" (ngSubmit)="submitForm()">
  <wm-alert *ngIf="(errors$ | async) as errors" [errors]="errors" (clickError)="setFocus($event)"> </wm-alert>    
  <wm-card styleClass="mar-btm-15">
    <div class="row">
      <div class="col-12">
        <wm-field>
          <h2 class="mar-btm-15" id="lblSearchBy">*How would you like to search?</h2>
          <div role="radiogroup" aria-labelledby="lblSearchBy">
            <wm-radio name="searchBy" formControlName="searchBy" value="W" label="Wellmark ID Number"
              inputId="searchBy">
            </wm-radio>
            <wm-radio name="searchBy" formControlName="searchBy" value="G" label="General Search" inputId="searchBy1">
            </wm-radio>
          </div>
        </wm-field>
      </div>
    </div>
  </wm-card>
  <app-wellmark-id-search *ngIf="form.get('searchBy').value === 'W'" [parentGroup]="this.form.get('id')"></app-wellmark-id-search>
  <!-- GENERAL -->
  <wm-card *ngIf="form.get('general').enabled" [formGroup]="form.get('general')" styleClass="mar-btm-15">
    <div class="row">
      <div class="col-12">
        <h2>General Search</h2>
        <wm-field>
          <label id="lblState" class="input-label">*State</label>
          <div role="radiogroup" aria-labelledby="lblState">
              <wm-radio 
              name="coverageState" 
              formControlName="coverageState"
              value="IA" 
              label="Iowa"
              inputId="coverageState">
              </wm-radio>
              <wm-radio 
              name="coverageState" 
              formControlName="coverageState"
              value="SD" 
              label="South Dakota"
              inputId="coverageState1">
              </wm-radio>
            </div>
        </wm-field>
      </div>
    </div>
    <div class="row">
        <div class="col-12">
          <wm-field>
            <label id="lblApplies" class="input-label">*Which applies to you?</label>
            <div role="radiogroup" aria-labelledby="lblApplies">
                <wm-radio 
                name="insuranceCategory" 
                formControlName="insuranceCategory"
                value="O" 
                label="I buy my own insurance"
                inputId="insuranceCategory">
                </wm-radio>
                <wm-radio 
                name="insuranceCategory" 
                formControlName="insuranceCategory"
                value="E" 
                label="My employer provides my insurance"
                inputId="insuranceCategory1">
                </wm-radio>
              </div>
          </wm-field>
        </div>
      </div>  
      <div class="row" *ngIf="form.get('general.insuranceType').enabled">
          <div class="col-12">
            <wm-field>
              <label id="lblType" class="input-label">*What type of insurance do you have?</label>
              <div role="radiogroup" aria-labelledby="lblType">
                  <wm-radio 
                  name="insuranceType" 
                  formControlName="insuranceType"
                  value="I" 
                  label="Individual and Family Plans"
                  inputId="insuranceType">
                  </wm-radio>
                  <wm-radio 
                  name="insuranceType" 
                  formControlName="insuranceType"
                  value="F" 
                  label="Farm Bureau Individual and Family Plans"
                  inputId="insuranceType1">
                  </wm-radio>
                  <wm-radio 
                  name="insuranceType" 
                  formControlName="insuranceType"
                  value="FBHBP" 
                  label="Farm Bureau Health Plans"
                  inputId="insuranceType2">
                  </wm-radio>                  
                </div>
            </wm-field>
          </div>
        </div>        
  </wm-card>
  <div class="mar-15">
      <button class="wm-btn primary trackCTA" type="submit">Search</button>
  </div>  
</form>