import { Component, OnInit } from '@angular/core';

import { FinderService } from '../../shared/services/finder.service';
import { SearchFacade } from '../../root-store';
import { User } from 'src/app/shared/models';

@Component({
  selector: 'app-search-container',
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.scss']
})
export class SearchContainerComponent implements OnInit {
  user: User;
  constructor(
    public finderService: FinderService,
    private searchState: SearchFacade
  ) { }

  ngOnInit() {
    this.searchState.user$.subscribe((user: User) => this.user = user);
    this.finderService.setTitle('Wellmark Blue Cross Blue Shield | Summary of Benefits and Coverage Manual Finder');
  }

}
